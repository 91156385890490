import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login'),
    },
    {
        path: '/',
        redirect: '/users'
    },
    {
        path: '',
        component: () => import('../views/app/index'),
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }
            return next()
        },
        children: [
            {
                path: '/users',
                name: 'users',
                component: () => import('../views/users'),
            },
            {
                path: '/user/:userId?',
                name: 'user-details',
                component: () => import('../views/users/AddUser'),
            },
            {
                path: '/users-account/:email',
                name: 'users-account',
                component: () => import('../views/users/userDetails'),
            },
        ]
    },
];

const router = new VueRouter({
    routes,
});


export default router
