import axios from 'axios'

export default {
    namespaced: true,
    state: {
        token: null,
        user: null
    },
    getters: {
        authenticated(state) {
            let isAuthenticated = false
            if (state.token && state.user) {
                isAuthenticated = true
            }
            return isAuthenticated
        },
        user(state) {
            return state.user
        },
        token(state) {
            return state.token
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_USER(state, data) {
            state.user = data
        },
    },
    actions: {
        async logIn({dispatch}, credentials) {
            let {data} = await axios.post('/v1/api/admin/login', credentials)
            return dispatch('attempt', data.value)
        },
        async attempt({commit, state}, token) {
            if (token) {
                commit('SET_TOKEN', token)
            }

            if (!state.token) {
                return
            }

            try {
                let {data} = await axios.get('/v1/api/admin/me', {headers: {token: token}} )
                commit('SET_USER', data.value)
            } catch (e) {
                console.log(e)
                commit('SET_USER', null)
                commit('SET_TOKEN', null)
            }
        },
        async logOut({commit}) {
            commit('SET_USER', null);
            return commit('SET_TOKEN', null)
        }
    },
    modules: {}
}
