<template>
  <div>
    <Navigation/>
    <notifications class="notifyMsg" group="foo"/>
  </div>
</template>


<style>
@import "assets/css/icofont/icofont.min.css";
@import "assets/css/boxicons/css/boxicons.min.css";
@import "assets/css/style.css";

.notifyMsg {
  z-index: 10000 !important;
}
</style>

<script>
import Navigation from './components/navigation/Navigation'


export default {
  components: {
    Navigation
  },
}
</script>
